import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";
import { formatImageUrl } from "../../../helper/helper";
const Card_BtnRight = (props: any) => {
  const { data } = props;
  const [bgImage, setBgImage] = useState(
    formatImageUrl(data?.background?.data?.attributes.url)
  );
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setBgImage(
          formatImageUrl(data?.background_mobile?.data?.attributes.url)
        );
      } else {
        setBgImage(formatImageUrl(data?.background?.data?.attributes.url));
      }
    };

    window.addEventListener("resize", handleResize);

    // Call the handler right away so the state gets updated with the initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);
  // let bgImage = formatImageUrl(data?.background?.data?.attributes.url);
  let brandLogo: any = formatImageUrl(data?.brand_logo?.data?.attributes?.url);
  if (!data) {
    return null; // Early return if data is not provided
  }
  return (
    <>
      <div
        className="slider-banner"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <Container>
          <Row>
            <Col md={6} xs={12} sx={12}>
              <div className="left-innercontainer">
                <div className="top-inner" data-aos="fade-up">
                  <img
                    src={data?.brand_logo ? `${brandLogo}` : ""}
                    className="brand_logo_img"
                  />
                  <p className="top-title">{data?.title}</p>
                  <h2>{data?.sub_title}</h2>
                  <p className="description">{data?.description}</p>
                  <div className="the-octaveBtn_wrapper">
                    {data ? (
                      <Button
                        btnText={data?.cta_title}
                        btnClass={"btnClass"}
                        btnLink={data?.cta_link}
                        linkOpenState={
                          data?.cta_open_in_new_tab == true ? "_blank" : ""
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} xs={12} sx={12}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Card_BtnRight;
