import React from "react";

const WhiskyRichnessBox = (props: any) => {
  const { data } = props;
  //   console.log("content", data);

  return (
    <section className="whisky_richness_section">
      <img
        src={data?.background?.data?.attributes?.url}
        alt="bg img"
        className="details_bg for_whisky_richness"
      />
      <div className="whisky_richness_content_section">
        <div className="whisky_richness_content" data-aos="fade-up">
          <img
            src={data?.background_mobile?.data?.attributes?.url}
            alt="bg img"
            className="content_middle_img"
          />
          <h3 data-aos="fade-up">{data?.title}</h3>
          <p data-aos="fade-up">{data?.content1}</p>
          {data?.content2 && <p>{data?.content2}</p>}

          <div className="whisky_details">
            {data?.items?.map((item: any, index: number) => {
              return (
                <div className="whisky_card" key={index}>
                  <div className="whisky_img">
                    <img src={item?.media?.data?.attributes?.url} alt="img" />
                  </div>
                  <h2 data-aos="fade-up">{item?.title}</h2>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhiskyRichnessBox;
