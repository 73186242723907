import Link from "next/link";
import React from "react";

const HospitalityBox = (props: any) => {
  const { data } = props;
  //   console.log("content", data);

  //   const data: any = [
  //     {
  //       title: "The Bank Restaurant",
  //       description:
  //         "Indulge in Gourmet Delights, Premium Whiskies, and Chic Luxury for a Stunning Dining Experience.",
  //       url: "./homepage/hospitality-img-one.png",
  //     },
  //     {
  //       title: "The Whisky club room",
  //       description:
  //         "Experience the Finest Whiskies at the Castle Hotel in Scotland - Sign Up for Our Historic Whisky Event Today!",
  //       url: "./homepage/hospitality-img-two.png",
  //     },
  //   ];
  return (
    <section className="dt_hospitality_section">
      <div className="dt_hospitality_title" data-aos="fade-up">
        <h2>{data?.header}</h2>
      </div>

      <div className="dt_hospitality_img_boxes">
        {data?.items?.map((item: any, index: number) => {
          return (
            <div
              className="dt_card"
              key={index}
              style={{
                backgroundImage: `url(${item?.background?.data?.attributes?.url})`,
              }}
              data-aos="fade-up"
            >
              <div className="dt_card_content">
                {item?.cta?.link ? (
                  <Link
                    href={item?.cta?.link}
                    target={item?.cta?.open_in_new_tab ? "_blank" : "_self"}
                  >
                    <h3 data-aos="fade-up">{item?.title}</h3>
                  </Link>
                ) : (
                  <h3 data-aos="fade-up">{item?.title}</h3>
                )}

                <p data-aos="fade-up">{item?.content1}</p>
                {item?.content2 && <p data-aos="fade-up">{item?.content2}</p>}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HospitalityBox;
