import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const data = [
  {
    imgurl: "landingpage/newsandblog1.png",
    headingText: "over 90 years in the making",
  },
  {
    imgurl: "landingpage/newsandblog2.png",
    headingText: "over 90 years in the making",
  },
  {
    imgurl: "landingpage/newsandblog3.png",
    headingText: "over 90 years in the making",
  },
  {
    imgurl: "landingpage/newsandblog4.png",
    headingText: "over 90 years in the making",
  },
];

const NewandBlogs = (props: any) => {
  const { newsdata } = props;

  return (
    <div className="eightfold-container">
      <Container>
        <Row>
          <Col md={12} xs={12}>
            {" "}
            <h2 className="headingText" data-aos="fade-up">{newsdata.header}</h2>
          </Col>
        </Row>
        <Row>
          {data && data.length > 0
            ? data.map((element: any, index: any) => {
                return (
                  <Col md={6} xs={12} key={index}>
                    <div
                      style={{
                        backgroundImage: `url(${element.imgurl})`,
                      }}
                      className="card"

                    >
                      <h3 data-aos="fade-up">{element.headingText}</h3>
                      <span data-aos="fade-up">Discover</span>
                    </div>
                  </Col>
                );
              })
            : null}
        </Row>
      </Container>
    </div>
  );
};

export default NewandBlogs;
