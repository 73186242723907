import React from "react";
import Button from "../common/CommonButton/Button";

const Sunflower = (props: any) => {
  const { data } = props;
  return (
    <section className="dt_sunflower_section">
      {data?.media?.data && (
        <img
          src={data?.media?.data?.attributes?.url}
          alt="bg img"
          className="details_bg for_whisky_richness onlyDesktop"
          data-aos="fade-up"
        />
      )}

      {data?.media_mobile?.data && (
        <img
          src={data?.media_mobile?.data?.attributes?.url}
          alt="bg img"
          className="details_bg for_whisky_richness onlyMobile"
          data-aos="fade-up"
        />
      )}

      <div className="content_container">
        <h2 data-aos="fade-up">{data?.header}</h2>

        {data?.content1 && (
          <p data-aos="fade-up" className="sunflower_content1">
            {data?.content1}
          </p>
        )}
        <p data-aos="fade-up" className="sunflower_content2">
          {data?.content2}
        </p>

        {data?.cta?.link && (
          <Button
            btnText={data?.cta?.title}
            btnLink={data?.cta?.link}
            linkOpenState={data?.cta?.open_in_new_tab}
            btnClass={"btn_sunflower"}
          />
        )}
      </div>

      {data?.video_link && (
        <div className="video_shadow">
          <video
            muted={true}
            loop
            preload="auto"
            autoPlay
            playsInline
            src={data?.video_link}
            className="video_for_desktop"
          />
        </div>
      )}
    </section>
  );
};

export default Sunflower;
