// import 'animate.css';
import classnames from "classnames";
import React, { useState } from "react";

const BannerVideo = (props: any) => {
  const { srcURL, data, pageName } = props;
  const [clicked, setClicked] = useState();
  const [sound, setSound] = useState(false);

  const handleToggleMute = () => {
    {
      !sound ? setSound(true) : setSound(false);
    }
  };
  const handleScrollClick = () => {
    const section = document.getElementById("crafted-section");
    section?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <div className="flex flex-1 banner_main_section">
        <video
          style={{ width: "100%", height: "100%" }}
          muted={!sound ? true : false}
          loop
          preload="auto"
          autoPlay
          playsInline
          // webkitplaysInline=""
          // x5playsInline=""
          src={srcURL}
        />
        {/* <div
          className={classnames(
            clicked ? "soundMainSection" : "soundMainSections",
            "css-om0y7o"
          )}
        >
          <div className="css-1ceik0d">
            <div className="css-18m3c3">
              <canvas className="css-w1atjl" width="42" height="16"></canvas>
            </div>
            <div className="css-fjbkjs" onClick={handleToggleMute}>
              {sound ? "Sound on" : "Sound off"}
            </div>
          </div>
        </div> */}
        <div className="text-wrapper">
          <h2 className="headingText">{data?.title}</h2>
          <h4 className="description">{data?.description}</h4>
          {pageName === "home" && (
            <img
              src={"./homepage/scroll-down-btn.png"}
              alt="scroll img"
              className="scroll_img"
              onClick={handleScrollClick}
            />
          )}
        </div>
        {/* {pageName === "home" && (
          <img
            src={"./homepage/scroll-down-btn.png"}
            alt="scroll img"
            className="scroll_img"
            onClick={handleScrollClick}
          />
        )} */}
      </div>
    </div>
  );
};

export default BannerVideo;
