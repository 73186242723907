import React from "react";

const ExperienceSection = (props: any) => {
  const { data } = props;
  // console.log("content", data);

  return (
    <section className="experience_section">
      <img
        src={data?.background?.data?.attributes?.url}
        alt="bg img"
        className="exp_bg"
      />

      <div className="experience_content" data-aos="fade-up">
        <h2 data-aos="fade-up">{data?.title}</h2>
        <p data-aos="fade-up">{data?.content1}</p>
        {data?.content2 && <p data-aos="fade-up">{data?.content2}</p>}
      </div>
    </section>
  );
};

export default ExperienceSection;
