import React from "react";
import { Container } from "react-bootstrap";

const BrandDetailsBox = (props: any) => {
  const { brandData, className, data } = props;
  return (
    <section className="brand_details_section">
      <Container>
        {data?.items?.map((item: any, index: any) => {
          let className =
            item?.type?.trim().toLowerCase() === "left" ? "left_side_box" : "";

          return (
            <div className={`brand_details_inner_div ${className}`}>
              <div className="brand_details">
                <div className="content" data-aos="fade-up">
                  <h2>{item?.title}</h2>
                  {item?.bottom_title && <h2>{item?.bottom_title}</h2>}

                  <p data-aos="fade-up">{item?.content1}</p>
                  {item?.content2 && <p data-aos="fade-up">{item?.content2}</p>}
                </div>
              </div>
              <div className="brand_img" data-aos="fade-up">
                <img src={item?.media?.data?.attributes?.url} alt="img" />
              </div>
            </div>
          );
        })}
      </Container>
    </section>
  );
};

export default BrandDetailsBox;
