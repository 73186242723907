"use client";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import BrandBanner from "./BrandBanner";

const BrandCollection = (props: any) => {
  const { branddata, maindata }: any = props;

  const [selectedSubMenuItemName, setSelectedSubMenuItemName]: any =
    useState("");
  const [tabitem, setTabitem]: any = useState([]);
  const [tabData, setTabData] = useState(null);
  const [selectedMenuData, setSelectedMenuData]: any = useState(null);
  useEffect(() => {
    branddata?.items[0] && setTabitem(branddata?.items[0].items);
    branddata?.items[0] && setSelectedSubMenuItemName(branddata?.items[0]);
    const findMainData = maindata.find(
      (item: any) => item?.__component === "sections.our-brands"
    );
    if (findMainData) {
      setTabData(findMainData);
      const findSliderData = findMainData.slides.find(
        (item: any) => item?.sub_title === branddata?.items[0]?.name
      );
      if (findSliderData) {
        setSelectedMenuData(findSliderData);
      }
    }
  }, [branddata?.items[0]]);

  const ChangeTabWithSlide = (name: any) => {
    const findMainData = maindata.find(
      (item: any) => item?.__component === "sections.our-brands"
    );
    const findSliderData = findMainData.slides.find(
      (item: any) => item?.sub_title === name
    );
    if (findSliderData) {
      setSelectedMenuData(findSliderData);
    }
  };

  return (
    <>
      <div className="forthFold-container">
        <h2 className="headingText" data-aos="fade-up">
          {branddata.header}
        </h2>
        <div className="inner-container">
          <div className="tabcontainer onlyDesktop tab_our_brand">
            <Container>
              <Row>
                <ul>
                  {branddata?.items.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <li
                          className={
                            selectedSubMenuItemName == item ? "active" : ""
                          }
                          onClick={() => {
                            setTabitem(item?.items);
                            setSelectedSubMenuItemName(item);
                            ChangeTabWithSlide(item?.name);
                          }}
                        >
                          <img
                            src={
                              item?.brand_image?.data?.attributes?.url
                                ? item?.brand_image?.data?.attributes?.url
                                : ""
                            }
                          />
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </Row>
            </Container>
          </div>
          <div className="tabcontainer onlyMobile">
            {branddata?.items.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`${
                    selectedSubMenuItemName == item ? "active" : ""
                  } card-item`}
                  onClick={() => {
                    setTabitem(item?.items);
                    setSelectedSubMenuItemName(item);
                    ChangeTabWithSlide(item?.name);
                  }}
                >
                  <img
                    src={
                      item.brand_image.data.attributes.url
                        ? item.brand_image.data.attributes.url
                        : ""
                    }
                    className="img1"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {tabData ? (
        <BrandBanner
          data={tabData}
          selectedSubMenuItemName={selectedSubMenuItemName}
          selectedMenuData={selectedMenuData}
        />
      ) : null}
    </>
  );
};

export default BrandCollection;
