"use client";
import React, { useEffect } from "react";

import AwardsRecognition from "@/components/landingpage/AwardsRecognition";
import NewandBlogs from "@/components/landingpage/NewandBlogs";
import OurStory from "@/components/landingpage/OurStory";
// import Ourbrands from "@/components/landingpage/Ourbrands";
import SignupForFree from "@/components/landingpage/SignupForFree";
import SocialMedia from "@/components/landingpage/SocialMedia";
import AOS from "aos";
import BannerVideo from "@/components/common/video";
import ScoMeta from "@/components/scometa";
import BrandCollection from "@/components/landingpage/BrandCollection";
import { Container } from "react-bootstrap";
import BrandDetailsBox from "@/components/homepage/BrandDetailsBox";
import HospitalityBox from "@/components/homepage/HospitalityBox";
import ExperienceSection from "@/components/homepage/ExperienceSection";
import DetailsSection from "@/components/homepage/DetailsSection";
import WhiskyRichnessBox from "@/components/homepage/WhiskyRichnessBox";
import ComingSoonBox from "@/components/homepage/ComingSoonBox";
import Sunflower from "@/components/homepage/Sunflower";

export default function HomePage(props: any) {
  const { homePageData, meta, countryCode } = props;

  useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration (in milliseconds)
      offset: 10, // Offset (in pixels) from the top of the viewport to trigger animation
    });
  }, []); // Empty dependency array means this effect runs once on mount and cleans up on unmount

 

  const sponsorData: any = {
    subTitle: "BLACK BULL SPONSORS",
    title: "SANDY MITCHELL & BAREWELL MOTORSPORTS",
    description:
      "For years, Black Bull Blended Scotch Whisky has proudly sponsored Sandy Mitchell in the British GT Championship. Sandy hails from near Black Bull's origins.",
    bgURL: "./homepage/details-bg.png",
    mobileImgURL: "./homepage/sponsor-mobile-img.png",
  };

  const visitData: any = {
    description:
      "The Spirits Embassy is a premier destination for connoisseurs seeking rare and meticulously crafted whiskies. We offer a curated selection that enhances and refines your collection, celebrating exceptional quality and unique character.",
    bgURL: "./homepage/visit-bg.png",
    mobileImgURL: "./homepage/visit-mobile-bg.png",
    logo: "./homepage/brand-logo.png",
  };

  const assoceationData: any = {
    title: "Nick faldo Colection",
    description:
      "Whisky or whiskey is a type of distilled alcoholic beverage made from fermented grain mash. Whisky or whiskey is a type of distilled alcoholic beverage made from fermented grain mash. ",
    bgURL: "./homepage/associate-bg.png",
    blackLogo: "./homepage/black-logo.png",
    logo: "./homepage/nick-logo.png",
    mobileImgURL: "./homepage/associate-mobile-bg.png",
  };

  const renderHomePage = () => {
    return (
      <div className="rederHomepage">
        {homePageData && homePageData.length > 0
          ? homePageData.map((content: any, index: any) => {
              switch (content.__component) {
                case "sections.banner":
                  let videosrc = content.video_link;
                  return (
                    <div key={index}>
                      <BannerVideo
                        srcURL={videosrc}
                        data={content}
                        pageName={"home"}
                      />
                    </div>
                  );

                // case "sections.our-brands":
                //   return (
                //     <Ourbrands
                //       headingText={"our brands"}
                //       key={content?.id + index}
                //       data={content}
                //     />
                //   );

                case "home.crafted-section":
                  return (
                    <div id="crafted-section">
                      <WhiskyRichnessBox
                        key={content?.id + index}
                        data={content}
                      />
                    </div>
                  );
                case "home.ecosystem":
                  return (
                    <>
                      <div className="main_title_section" data-aos="fade-up">
                        <Container>
                          <h2 data-aos="fade-up">{content?.header}</h2>
                          <h2 data-aos="fade-up">{content?.bottom_header}</h2>
                        </Container>
                      </div>
                      <BrandDetailsBox
                        key={content?.id + index}
                        data={content}
                        className=""
                      />
                    </>
                  );

                case "home.hospitality":
                  return (
                    <HospitalityBox
                      key={content?.id + index}
                      data={content}
                      className=""
                    />
                  );
                case "home.castle-hotel":
                  return (
                    <ExperienceSection
                      data={content}
                      key={content?.id + index}
                    />
                  );
                case "home.spirit-section":
                  return (
                    <ComingSoonBox key={content?.id + index} data={content} />
                  );

                case "home.brand-association":
                  return (
                    <div className="main_title_section" data-aos="fade-up">
                      <Container>
                        <h2 data-aos="fade-up" className="nick_title">
                          {content?.header}
                        </h2>
                      </Container>
                      <DetailsSection
                        associateData={content}
                        data={assoceationData}
                        tag="associate"
                        mainTitle="Our Brand Association"
                      />
                    </div>
                    // <DetailsSection
                    //   key={content?.id + index}
                    //   data={assoceationData}
                    //   tag="associate"
                    //   mainTitle="Our Brand Association"
                    // />
                  );

                case "home.sponsors-section":
                  return (
                    <DetailsSection
                      sponsorData={content}
                      data={sponsorData}
                      tag="sponsor"
                      key={content?.id + index}
                    />
                  );
                case "home.sunflower":
                  return (
                    <Sunflower
                      data={content}
                      tag="Sunflower"
                      key={content?.id + index}
                    />
                  );
                case "home.spirit-embassy":
                  return (
                    <DetailsSection
                      visitData={content}
                      data={visitData}
                      tag="visit"
                      key={content?.id + index}
                    />
                  );

                case "sections.explore-brands":
                  return (
                    <BrandCollection
                      key={content?.id + index}
                      branddata={content}
                      maindata={homePageData}
                    />
                  );

                // case "sections.our-story":
                //   return <OurStory data={content} key={content?.id + index} />;

                case "sections.awards-recognitions":
                  return (
                    <AwardsRecognition
                      key={content?.id + index}
                      data={content}
                    />
                  );
                // case "sections.social-media":
                //   return (
                //     <SocialMedia
                //       key={content?.id + index}
                //       socialdata={content}
                //     />
                //   );
                case "sections.news-and-blogs":
                  return (
                    <NewandBlogs key={content?.id + index} newsdata={content} />
                  );

                default:
                  return null;
              }
            })
          : null}
      </div>
    );
  };

  return (
    <>
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      {homePageData && renderHomePage()}

      <SignupForFree />
    </>
  );
}
