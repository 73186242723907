import React from "react";

const ComingSoonBox = (props: any) => {
  const { data } = props;
  // console.log("content", data);
  return (
    <section className="coming_soon_section">
      {data?.video_link && (
        <video
          muted={true}
          loop
          preload="auto"
          autoPlay
          playsInline
          src={data?.video_link}
          className="video_for_desktop"
        />
      )}

      <div className="coming_soon_content_box">
        <div className="coming_soon_inner_text" data-aos="fade-up">
          <h4 data-aos="fade-up">{data?.top_title}</h4>
          <h3>{data?.title}</h3>
          {data?.video_link && (
            <div className="video_shadow">
              <video
                muted={true}
                loop
                preload="auto"
                autoPlay
                playsInline
                src={data?.video_link}
                className="video_for_mobile"
              />
            </div>
          )}

          <p data-aos="fade-up">{data?.content1}</p>

          {data?.content2 && <p data-aos="fade-up">{data?.content2}</p>}
        </div>
      </div>
    </section>
  );
};

export default ComingSoonBox;
