import React from "react";

const AwardsRecognition = (props: any) => {
  const { data } = props;
  return (
    <div className="sixthFold-container">
      <h2 className="headingText" data-aos="fade-up">
        {data?.header}
      </h2>
      <p className="subText" data-aos="fade-up">
        {data?.sub_header}
      </p>
      <div className="btncontainer onlyMobile">
        <button className="btn ">{data?.cta?.title}</button>
      </div>
      <div className="cardContainer">
        {data?.images?.data && data?.images?.data.length > 0
          ? data?.images?.data.slice(0, 5).map((element: any, index: any) => {
              return (
                <div className="card" key={index}>
                  <img
                    src={
                      element?.attributes?.url ? element?.attributes?.url : ""
                    }
                  />
                </div>
              );
            })
          : null}
      </div>
      <div className="btncontainer onlyDesktop">
        <button className="btn">{data?.cta?.title}</button>
      </div>
    </div>
  );
};

export default AwardsRecognition;
