import React from "react";
import { Container } from "react-bootstrap";
import Button from "../common/CommonButton/Button";

const DetailsSection = (props: any) => {
  const { data, tag, mainTitle, visitData, sponsorData, associateData } = props;
  const getMiddleContent = (tagName: any) => {
    switch (tagName) {
      case "sponsor":
        return (
          <div className="details_content_section">
            <div className="content" data-aos="fade-up">
              <h4 data-aos="fade-up">{sponsorData?.top_title}</h4>
              <h3 data-aos="fade-up">{sponsorData?.title}</h3>
              <img
                src={sponsorData?.media_mobile?.data?.attributes?.url}
                alt="bg img"
                className="content_middle_img"
              />
              <p data-aos="fade-up">{sponsorData?.content1}</p>
              {sponsorData?.content2 && <p data-aos="fade-up">{sponsorData?.content2}</p>}
            </div>
          </div>
        );
        break;

      case "visit":
        return (
          <div className="details_content_section visit_section">
            <div className="content" data-aos="fade-up">
              <img
                src={visitData?.logo?.data?.attributes?.url}
                alt="logo"
                className="brand_logo"
              />
              <p data-aos="fade-up">{visitData?.content1}</p>
              {visitData?.content2 && <p data-aos="fade-up">{visitData?.content2}</p>}
              {visitData?.cta?.link && (
                <Button
                  btnText={visitData?.cta?.title}
                  btnLink={visitData?.cta?.link}
                  linkOpenState={visitData?.cta?.open_in_new_tab}
                  btnClass={"btn_visit"}
                />
              )}
              <img
                src={visitData?.background_mobile?.data?.attributes?.url}
                alt="bg img"
                className="content_middle_img"
              />
            </div>
          </div>
        );
        break;

      case "associate":
        return (
          <div className="details_content_section visit_section associate_section">
            <div className="content" data-aos="fade-up">
              <img
                src={associateData?.media_mobile?.data?.attributes?.url}
                alt="bg img"
                className="content_middle_img"
              />
              <h3 data-aos="fade-up">{associateData?.title}</h3>
              <p data-aos="fade-up">{associateData?.content1}</p>
              {associateData?.content2 && <p data-aos="fade-up">{associateData?.content2}</p>}
              <img
                src={associateData?.nick_logo?.data?.attributes?.url}
                alt="logo"
                className="nick_logo"
                data-aos="fade-up"
              />
              <img
                src={associateData?.blackbull_logo?.data?.attributes?.url}
                alt="bg img"
                className="logos_img"
                data-aos="fade-up"
              />
            </div>
          </div>
        );
        break;

      default:
        break;
    }
  };
  return (
    <section className="details_section">
      <img src={data?.bgURL} alt="bg img" className="details_bg" />
      {getMiddleContent(tag)}
    </section>
  );
};

export default DetailsSection;
