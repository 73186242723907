import Card_BtnRight from "../landingpageSlider/Card_BtnRight";

const BrandBanner = (props: any) => {
  const { selectedMenuData }: any = props;

  return (
    <div className="landingpage">
      <div className="slider-horizontalline">
        <Card_BtnRight data={selectedMenuData} />
      </div>
    </div>
  );
};

export default BrandBanner;
